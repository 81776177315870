
































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import UserAdapter from '@/adapter/userAdapter';

@Component({
  computed: mapGetters(['user']),
})
export default class ArtistDrawerHeader extends Vue {
  user!: UserAdapter | null;

  // eslint-disable-next-line global-require
  iconSrc: string = require('@/assets/defaults/default_icon.png');

  async created(): Promise<void> {
    if (this.user === null) {
      await this.$router.push('/auth');
      return;
    }
    this.iconSrc = this.user.icon_presigned_get_url;
  }
}
