


































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import UserAdapter from '@/adapter/userAdapter';
import ArtistList from './List.vue';
import ArtistDrawerHeader from './widgets/DrawerHeader.vue';

@Component({
  computed: mapGetters(['user']),
  components: {
    ArtistList,
    ArtistDrawerHeader,
  },
})
export default class ArtistDrawer extends Vue {
  user!: UserAdapter;

  drawer = false;

  image = 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg';

  gradient = 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)';

  mini = false;

  items = [
    {
      title: 'アート登録・一覧',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/pencil.png'),
      to: '/artist/art',
    },
    {
      title: 'アナリティクス',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/analytics.svg'),
      to: '/artist/analytics',
    },
    {
      title: 'グッズ製作・一覧',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/goods.png'),
      to: '/artist/product',
    },
    {
      title: '売上ダッシュボード',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/dashboard.png'),
      to: '/artist/dashboard',
    },
    {
      title: 'プロフィール',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/profile.png'),
      to: '/artist/profile',
    },
    {
      title: '銀行口座登録',
      // eslint-disable-next-line global-require
      icon: require('@/assets/icon/artist_sidebar/bank.png'),
      to: '/artist/setting',
    },
  ];

  // eslint-disable-next-line global-require
  iconSrc: string = require('@/assets/defaults/default_icon.png');

  created(): void {
    this.iconSrc = this.user.icon_presigned_get_url;
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        this.drawer = false;
        break;
      case 'sm':
        this.drawer = false;
        break;
      case 'md':
        this.drawer = true;
        break;
      case 'lg':
        this.drawer = true;
        break;
      case 'xl':
        this.drawer = true;
        break;
      default:
        this.drawer = true;
        break;
    }
  }

  get hiddenAppBar(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return false;
      case 'sm':
        return false;
      case 'md':
        return true;
      case 'lg':
        return true;
      case 'xl':
        return true;
      default:
        return true;
    }
  }

  toArtistProfileEdit(): void {
    this.$router.push('/artist/profile');
  }
}
