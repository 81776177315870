










import { Component, Vue } from 'vue-property-decorator';
import ArtistDrawer from './Drawer.vue';
import ArtistView from './View.vue';
import Footer from '../common/Footer.vue';

@Component({
  components: {
    ArtistDrawer,
    ArtistView,
    Footer,
  },
})
export default class ArtistIndex extends Vue {
}
