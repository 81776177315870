




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ArtistListItem extends Vue {
  @Prop() item?: any;

  get title(): string {
    // iconが設定されていなかった場合に、単語の頭文字でiconの代わりにする
    const matches = this.item.title.match(/\b(\w)/g);

    return matches.join('');
  }
}
