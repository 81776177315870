
















import { Component, Prop, Vue } from 'vue-property-decorator';
import ArtistListItem from './ListItem.vue';

@Component({
  components: {
    ArtistListItem,
  },
})
export default class ArtistList extends Vue {
  @Prop() items?: Array<any>;
}
