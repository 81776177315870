








import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/layouts/common/Footer.vue';

@Component({
  components: { Footer },
})
export default class ArtistView extends Vue {
}
